import z from 'zod';
import { KeepTypeAlias } from '../utilities';

export const collectionInstanceIdentifierSchema = z.number();

export type CollectionInstanceIdentifier = z.infer<typeof collectionInstanceIdentifierSchema>;

export const collectionInstanceIdentifiersSchema = z.record(z.string(), collectionInstanceIdentifierSchema);

export type CollectionInstanceIdentifiers = KeepTypeAlias<z.infer<typeof collectionInstanceIdentifiersSchema>>;

// TODO: Change name NodeInstance to AnswerInstance | Instance...
export type NodeInstance = {
  id: string;
  collectionInstanceIdentifiers: CollectionInstanceIdentifiers;
};

export type RepeatedAnswers<T extends string> = {
  repeatedIndex: CollectionInstanceIdentifier;
  answersByNodeId: Partial<Record<T, any>>;
};

type SurrogateId = string;

export type RepeatedAnswersBySurrogateId<T extends string> = Record<SurrogateId, RepeatedAnswers<T>>;
