export type NotificationEnabled = boolean | null;

export enum NotificationTypes {
  leadScheduledACallEmail = 'leadScheduledACallEmail',
  leadCompletedNeedsAnalysisEmail = 'leadCompletedNeedsAnalysisEmail',
}

export type UserNotificationOptions = {
  [key in NotificationTypes]: NotificationEnabled;
};

export type UserNotificationPreference = {
  userId: string;
  createdAt?: string;
  updatedAt?: string;
} & UserNotificationOptions;
