import { GenderAtBirth } from '../answers';
import { SupportedFileExtType, SupportedFileMimeType } from '../models';

export enum SmokingStatus {
  smoker = 'smoker',
  nonSmoker = 'nonSmoker',
}

type Age = string;

type CoverageAmountRangeAndRate = {
  minimum: number;
  maximum: number;
  rate: number;
};

export type PricingData = Record<Age, CoverageAmountRangeAndRate[]>;

export type FormattedPricingData = {
  id: string | number;
  smokingStatus: SmokingStatus;
  genderAtBirth: GenderAtBirth;
  age: number;
  coverageAmountMinimum: number;
  coverageAmountMaximum: number;
  rate: number;
}[];

export enum PaymentFrequency {
  annually = 'annually',
  monthly = 'monthly',
  quarterly = 'quarterly',
  semiAnnually = 'semiAnnually',
}

export type ModalFactor = Partial<Record<PaymentFrequency, number>>;

export type InsuranceProductPricing = {
  id: string;
  insuranceProductId: string;
  pricing: FormattedPricingData;
  modalFactor?: ModalFactor;
  policyFee?: number;
  createdAt: Date;
  updatedAt: Date;
};

export const supportedProductPricingMimeTypes = [SupportedFileMimeType.excel];
export type SupportedProductPricingMimeType = (typeof supportedProductPricingMimeTypes)[number];

export const supportedProductPricingExtTypes = [SupportedFileExtType.xlsx, SupportedFileExtType.xls];
export type SupportedProductPricingExtType = (typeof supportedProductPricingExtTypes)[number];
