export enum GenderAtBirth {
  male = 'male',
  female = 'female',
}

export enum MaritalStatus {
  civilUnion = 'civilUnion',
  commonLaw = 'commonLaw',
  divorced = 'divorced',
  domesticPartner = 'domesticPartner',
  estranged = 'estranged',
  legallySeparated = 'legallySeparated',
  married = 'married',
  single = 'single',
  widowed = 'widowed',
  other = 'other',
  unknown = 'unknown',
  headOfHousehold = 'headOfHousehold',
  marriedWithHoldingSingle = 'marriedWithHoldingSingle',
  child = 'child',
}

export enum InterviewLanguage {
  english = 'english',
  french = 'french',
  arabic = 'arabic',
  bengali = 'bengali',
  cantonese = 'cantonese',
  farsi = 'farsi',
  hindi = 'hindi',
  japanese = 'japanese',
  korean = 'korean',
  mandarin = 'mandarin',
  portuguese = 'portuguese',
  punjabi = 'punjabi',
  spanish = 'spanish',
  tagalog = 'tagalog',
  tamil = 'tamil',
  urdu = 'urdu',
  vietnamese = 'vietnamese',
  other = 'other',
}

export enum PolicyProductType {
  criticalIllness = 'ci',
  wholeLife = 'life',
  disabilityBuyOut = 'disability',
  accidentHealth = 'health',
  unknown = 'unknown',
  term = 'term',
}

export enum PolicyType {
  permanentInsurance = 'permanentInsurance',
  termInsurance = 'termInsurance',
  participatingWholeLifeInsurance = 'participatingWholeLifeInsurance',
}

export enum CountryCode {
  canada = 'CA',
  unitedStates = 'US',
  // We add other countries later
}

export enum Country {
  afghanistan = 'afghanistan',
  alandIslands = 'alandIslands',
  albania = 'albania',
  algeria = 'Algeria',
  americanSamoa = 'americanSamoa',
  andorra = 'andorra',
  angola = 'angola',
  anguilla = 'anguilla',
  antarctica = 'antarctica',
  antiguaAndBarbuda = 'antiguaAndBarbuda',
  argentina = 'argentina',
  armenia = 'armenia',
  aruba = 'aruba',
  australia = 'australia',
  austria = 'austria',
  azerbaijan = 'azerbaijan',
  bahamas = 'bahamas',
  bahrain = 'bahrain',
  bangladesh = 'bangladesh',
  barbados = 'barbados',
  belarus = 'belarus',
  belgium = 'belgium',
  belize = 'belize',
  benin = 'benin',
  bermuda = 'bermuda',
  bhutan = 'bhutan',
  bolivia = 'bolivia',
  bonaireSintEustatiusAndSaba = 'bonaireSintEustatiusAndSaba',
  bosniaAndHerzegovina = 'bosniaAndHerzegovina',
  botswana = 'botswana',
  bouvetIsland = 'bouvetIsland',
  brazil = 'brazil',
  britishIndianOceanTerritory = 'britishIndianOceanTerritory',
  bruneiDarussalam = 'bruneiDarussalam',
  bulgaria = 'bulgaria',
  burkinaFaso = 'burkinaFaso',
  burundi = 'burundi',
  cambodia = 'cambodia',
  cameroon = 'cameroon',
  canada = 'canada',
  capeVerde = 'capeVerde',
  caymanIsland = 'caymanIsland',
  centralAfricanRepublic = 'centralAfricanRepublic',
  chad = 'chad',
  chile = 'chile',
  china = 'china',
  christmasIsland = 'christmasIsland',
  cocosIslands = 'cocosIslands',
  colombia = 'colombia',
  comoros = 'comoros',
  congo = 'congo',
  congoTheDemocraticRepublic = 'congoTheDemocraticRepublic',
  cookIslands = 'cookIslands',
  costaRica = 'costaRica',
  coteIvoire = 'coteIvoire',
  croatia = 'croatia',
  cuba = 'cuba',
  curacao = 'curacao',
  cyprus = 'cyprus',
  czechRepublic = 'czechRepublic',
  denmark = 'denmark',
  djibouti = 'djibouti',
  dominica = 'dominica',
  dominicanRepublic = 'dominicanRepublic',
  ecuador = 'ecuador',
  egypt = 'egypt',
  elSalvador = 'elSalvador',
  equatorialGuinea = 'equatorialGuinea',
  eritrea = 'eritrea',
  estonia = 'estonia',
  ethiopia = 'ethiopia',
  falklandIslands = 'falklandIslands',
  faroeIslands = 'faroeIslands',
  fiji = 'fiji',
  finland = 'finland',
  france = 'france',
  frenchGuiana = 'frenchGuiana',
  frenchPolynesia = 'frenchPolynesia',
  frenchSouthernTerritories = 'frenchSouthernTerritories',
  gabon = 'gabon',
  gambia = 'gambia',
  georgia = 'georgia',
  germany = 'germany',
  ghana = 'ghana',
  gibraltar = 'gibraltar',
  greece = 'greece',
  greenland = 'greenland',
  grenada = 'grenada',
  guadeloupe = 'guadeloupe',
  guam = 'guam',
  guatemala = 'guatemala',
  guernsey = 'guernsey',
  guinea = 'guinea',
  guineaBissau = 'guineaBissau',
  guyana = 'guyana',
  haiti = 'haiti',
  heardIslandAndMcDonaldIslands = 'heardIslandAndMcDonaldIslands',
  holySee = 'holySee',
  honduras = 'honduras',
  hongKong = 'hongKong',
  hungary = 'hungary',
  iceland = 'iceland',
  india = 'india',
  indonesia = 'indonesia',
  iran = 'iran',
  iraq = 'iraq',
  ireland = 'ireland',
  isleOfMan = 'isleOfMan',
  israel = 'israel',
  italy = 'italy',
  jamaica = 'jamaica',
  japan = 'japan',
  jersey = 'Jersey',
  jordan = 'Jordan',
  kazakhstan = 'kazakhstan',
  kenya = 'kenya',
  kiribati = 'kiribati',
  northKorea = 'northKorea',
  southKorea = 'southKorea',
  kosovo = 'kosovo',
  kuwait = 'kuwait',
  kyrgyzstan = 'kyrgyzstan',
  laoPeopleDemocraticRepublic = 'laoPeopleDemocraticRepublic',
  latvia = 'Latvia',
  lebanon = 'Lebanon',
  lesotho = 'Lesotho',
  liberia = 'Liberia',
  libya = 'Libya',
  liechtenstein = 'Liechtenstein',
  lithuania = 'Lithuania',
  luxembourg = 'Luxembourg',
  macao = 'Macao',
  macedonia = 'Macedonia',
  madagascar = 'Madagascar',
  malawi = 'Malawi',
  malaysia = 'Malaysia',
  maldives = 'Maldives',
  mali = 'Mali',
  malta = 'Malta',
  marshallIslands = 'marshallIslands',
  martinique = 'Martinique',
  mauritania = 'Mauritania',
  mauritius = 'Mauritius',
  mayotte = 'Mayotte',
  mexico = 'Mexico',
  micronesia = 'Micronesia',
  moldova = 'Moldova',
  monaco = 'Monaco',
  mongolia = 'Mongolia',
  montenegro = 'Montenegro',
  montserrat = 'Montserrat',
  morocco = 'Morocco',
  mozambique = 'Mozambique',
  myanmar = 'Myanmar',
  namibia = 'Namibia',
  nauru = 'Nauru',
  nepal = 'Nepal',
  netherlands = 'NetherlandsNetherlands',
  netherlandsAntilles = 'NetherlandsAntilles',
  newCaledonia = 'NewCaledonia',
  newZealand = 'NewZealand',
  nicaragua = 'Nicaragua',
  niger = 'Niger',
  nigeria = 'Nigeria',
  niue = 'Niue',
  norfolkIsland = 'NorfolkIsland',
  northYemen = 'NorthYemen',
  northernMarianaIslands = 'NorthernMarianaIslands',
  norway = 'Norway',
  oman = 'Oman',
  pakistan = 'Pakistan',
  palau = 'Palau',
  palestine = 'Palestine',
  panama = 'Panama',
  papuaNewGuinea = 'PapuaNewGuinea',
  paraguay = 'Paraguay',
  peru = 'Peru',
  philippines = 'Philippines',
  pitcairn = 'Pitcairn',
  poland = 'Poland',
  portugal = 'Portugal',
  puertoRico = 'PuertoRico',
  qatar = 'Qatar',
  reunion = 'Reunion',
  romania = 'Romania',
  russianFederation = 'RussianFederation',
  rwanda = 'Rwanda',
  saintBarthelemy = 'SaintBarthelemy',
  saintHelena = 'SaintHelena',
  saintKittsandNevis = 'SaintKittsandNevis',
  saintLucia = 'SaintLucia',
  saintMartin = 'SaintMartin',
  saintPierreAndMiquelon = 'SaintPierreAndMiquelon',
  saintVincentAndTheGrenadines = 'SaintVincentAndTheGrenadines',
  samoa = 'Samoa',
  sanMarino = 'SanMarino',
  saoTomeAndPrincipe = 'SaoTomeAndPrincipe',
  saudiArabia = 'SaudiArabia',
  senegal = 'Senegal',
  serbia = 'Serbia',
  seychelles = 'Seychelles',
  sierraLeone = 'SierraLeone',
  singapore = 'Singapore',
  sintMaarten = 'SintMaarten',
  slovakia = 'Slovakia',
  slovenia = 'Slovenia',
  solomonIslands = 'SolomonIslands',
  somalia = 'Somalia',
  southAfrica = 'SouthAfrica',
  southGeorgia = 'SouthGeorgia',
  southSudan = 'SouthSudan',
  southYemen = 'SouthYemen',
  spain = 'Spain',
  sriLanka = 'SriLanka',
  sudan = 'Sudan',
  suriname = 'Suriname',
  svalbardAndJanMayen = 'SvalbardAndJanMayen',
  swaziland = 'Swaziland',
  sweden = 'Sweden',
  switzerland = 'Switzerland',
  syrianArabRepublic = 'SyrianArabRepublic',
  taiwan = 'Taiwan',
  tajikistan = 'Tajikistan',
  tanzania = 'Tanzania',
  thailand = 'Thailand',
  timorLeste = 'TimorLeste',
  togo = 'Togo',
  tokelau = 'Tokelau',
  tonga = 'Tonga',
  trinidadAndTobago = 'TrinidadAndTobago',
  tunisia = 'Tunisia',
  turkey = 'Turkey',
  turkmenistan = 'Turkmenistan',
  turksandCaicosIslands = 'TurksandCaicosIslands',
  tuvalu = 'Tuvalu',
  uganda = 'Uganda',
  unionOfSovietSocialistRepublics = 'UnionOfSovietSocialistRepublics',
  unitedArabEmirate = 'UnitedArabEmirate',
  unitedKingdom = 'UnitedKingdom',
  unitedStatesMinorOutlyingIslands = 'UnitedStatesMinorOutlyingIslands',
  unitedStates = 'unitedStates',
  uruguay = 'Uruguay',
  uzbekistan = 'Uzbekistan',
  vanuatu = 'Vanuatu',
  venezuela = 'Venezuela',
  vietNam = 'VietNam',
  virginIslandsBritish = 'VirginIslandsBritish',
  virginIslandsUS = 'VirginIslandsUS',
  wallisAndFutuna = 'WallisAndFutuna',
  westernSahara = 'WesternSahara',
  yemen = 'Yemen',
  yugoslavia = 'Yugoslavia',
  zambia = 'Zambia',
  zimbabwe = 'Zimbabwe',
  other = 'otherCountry',
  unknown = 'Unknown',
}

export enum UnemploymentReason {
  student = 'student',
  retired = 'retired',
  other = 'other',
  currentlyLookingForWork = 'currentlyLookingForWork',
  disability = 'disability',
  maternityLeaveOrAtHomeWithFamily = 'maternityLeaveOrAtHomeWithFamily',
}

export enum CanadianProvince {
  Alberta = 'AB',
  BritishColumbia = 'BC',
  NewfoundlandAndLabrador = 'NL',
  Manitoba = 'MB',
  NewBrunswick = 'NB',
  NorthwestTerritories = 'NT',
  NovaScotia = 'NS',
  Nunavut = 'NU',
  Ontario = 'ON',
  PrinceEdwardIsland = 'PE',
  Quebec = 'QC',
  Saskatchewan = 'SK',
  Yukon = 'YT',
  other = 'other',
  unknown = 'unknown',
}

export enum UsState {
  Alabama = 'AL',
  Alaska = 'AK',
  Arizona = 'AZ',
  Arkansas = 'AR',
  California = 'CA',
  Colorado = 'CO',
  Connecticut = 'CT',
  Delaware = 'DE',
  Florida = 'FL',
  Georgia = 'GA',
  Hawaii = 'HI',
  Idaho = 'ID',
  Illinois = 'IL',
  Indiana = 'IN',
  Iowa = 'IA',
  Kansas = 'KS',
  Kentucky = 'KY',
  Louisiana = 'LA',
  Maine = 'ME',
  Maryland = 'MD',
  Massachusetts6 = 'MA',
  Michigan = 'MI',
  Minnesota = 'MN',
  Mississippi = 'MS',
  Missouri = 'MO',
  Montana = 'MT',
  Nebraska = 'NE',
  Nevada = 'NV',
  NewHampshire = 'NH',
  NewJersey = 'NJ',
  NewMexico = 'NM',
  NewYork = 'NY',
  NorthCarolina = 'NC',
  NorthDakota = 'ND',
  Ohio = 'OH',
  Oklahoma = 'OK',
  Oregon = 'OR',
  Pennsylvania = 'PA',
  RhodeIsland = 'RI',
  SouthCarolina = 'SC',
  SouthDakota = 'SD',
  Tennessee = 'TN',
  Texas = 'TX',
  Utah = 'UT',
  Vermont = 'VT',
  Virginia = 'VA',
  Washington = 'WA',
  WestVirginia = 'WV',
  Wisconsin = 'WI',
  Wyoming = 'WY',
  other = 'other',
  unknown = 'unknown',
}

export enum TelephoneType {
  cellularTelephoneType = 'cellularTelephoneType',
  homeTelephoneType = 'homeTelephoneType',
  workTelephoneType = 'workTelephoneType',
  otherTelephoneType = 'otherTelephoneType',
}

export enum CallTime {
  morning = 'morning',
  afternoon = 'afternoon',
  evening = 'evening',
  anyTime = 'anyTime',
  dayTime = 'dayTime',
}

export enum BestDayToCall {
  thursday = 'thursday',
  anyDay = 'anyDay',
  weekdays = 'weekdays',
  tuesday = 'tuesday',
  wednesday = 'wednesday',
  friday = 'friday',
  saturday = 'saturday',
  monday = 'monday',
  unknown = 'unknown',
  sunday = 'sunday',
  other = 'other',
}

export enum TeleInterviewPhoneType {
  mobile = 'Mobile',
  home = 'Home',
  work = 'Business',
  other = 'Other',
}

export enum AddressType {
  residential = 'residential',
  mailing = 'mailing',
  other = 'other',
  unknown = 'unknown',
  previous = 'previous',
  business = 'business',
}

export enum PaymentMethodType {
  payrollDeduction = 'payrollDeduction',
  annualCheque = 'annualCheque',
  preAuthorizedDebit = 'preAuthorizedDebit', //not in acord documentation
  existingPAD = 'existingPAD', //not in acord documentation
  other = 'other',
  unknown = 'unknown',
  annually = 'annually',
  quarterly = 'quarterly',
  monthly = 'monthly',
}

export enum DepositMethod {
  monthlyPAD = 'monthlyPre-AuthorizedDebit(PAD)',
  lumpsumDeposit = 'lumpsumDeposit',
  externalTransfer = 'transferFromOtherFinancialInstitution(S)',
  internalTransfer = 'transferFromExistingSereniaLifePolicy(Ies)',
}

export enum InvestmentSubaccountType {
  pad = 'pad',
  allocation = 'allocation',
  autoSweep = 'autoSweep',
}

export enum PayorType {
  otherIndividual = 'otherIndividual',
  insured = 'insured',
  owner = 'owner',
  otherEntity = 'otherEntity',
  annuitant = 'annuitant',
}

export enum OwnerType {
  applicantTypeEntity = 'applicantTypeEntity',
  applicantTypeIndividual = 'applicantTypeIndividual',
  applicantTypeJoint = 'applicantTypeJoint',
}

export enum ContributorType {
  individual = 'individual',
  businessOrEntity = 'businessOrEntity',
  spousal = 'spousal',
}

export enum SecondAccountHolderType {
  otherParty = 'otherIndividual(ThirdPartyPayor)',
  owner = 'owner',
}

export enum ContributorIndividualType {
  owner = 'owner',
  annuitant = 'annuitant',
  otherIndividual = 'otherIndividual(ThirdPartyContributor)',
}

export enum IdentityVerificationType {
  passport = 'passport',
  driversLicense = 'driversLicense',
  stateID = 'stateID',
  permanentResidentCard = 'permanentResidentCard',
  citizenshipCard = 'citizenshipCard(IssuedPriorTo2012)',
  secureCertificateOfIndianStatus = 'secureCertificateOfIndianStatus',
}

export enum PartyType {
  person = 'person',
  organization = 'organization',
  unknown = 'unknown',
  entity = 'entity',
}

export enum IndicatorCode {
  base = 'base',
  rider = 'rider',
  unknown = 'unknown',
}

export enum DividendOptions {
  paidUpAdditions = 'paidUpAdditions',
  dividendsOnDeposit = 'dividendsOnDeposit',
  cash = 'cash',
}

export enum BeneficiaryRoleType {
  aunt = 'aunt',
  brother = 'brother',
  brotherInLaw = 'brotherInLaw',
  child = 'child',
  commonLawHusband = 'commonLawHusband',
  commonLawWife = 'commonLawWife',
  cousin = 'cousin',
  daughter = 'daughter',
  daughterInLaw = 'daughterInLaw',
  employer = 'employer',
  executor = 'executor',
  father = 'father',
  fatherInLaw = 'fatherInLaw',
  fiance = 'fiancé',
  fiancee = 'fiancée',
  friend = 'friend',
  formerSpouse = 'formerSpouse',
  boyFriend = 'boyFriend',
  girlFriend = 'girlFriend',
  goddaughter = 'goddaughter',
  godson = 'godson',
  godfather = 'godfather',
  godmother = 'godmother',
  granddaughter = 'granddaughter',
  grandson = 'grandson',
  grandfather = 'grandfather',
  grandmother = 'grandmother',
  husband = 'husband',
  legalGuardian = 'legalGuardian',
  mother = 'mother',
  motherInLaw = 'motherInLaw',
  nephew = 'nephew',
  niece = 'niece',
  other = 'other',
  partner = 'partner',
  self = 'self',
  sister = 'sister',
  sisterInLaw = 'sisterInLaw',
  son = 'son',
  sonInLaw = 'sonInLaw',
  spouse = 'spouse',
  stepdaughter = 'stepdaughter',
  stepson = 'stepson',
  stepfather = 'stepfather',
  stepmother = 'stepmother',
  uncle = 'uncle',
  wife = 'wife',
  unknown = 'unknown',
}

export enum TimeOfDay {
  all = 'all',
  day = 'day',
  night = 'night',
}

export enum HealthConsultationType {
  personalHealthQuestionnaire = 'personalHealthQuestionnaire',
  teleInterview = 'teleInterview',
}
