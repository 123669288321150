import { LineOfBusinessName } from '../linesOfBusiness';
import { Answers } from '../models';

export class ProCompInputDataValidationError extends Error {
  readonly tag = 'ProCompInputDataValidationError';
}

export type GetAdoMinMaxOutput = {
  value: number;
  nodeId: string;
}[];

export type FetchAdoMinMaxRequest = {
  answers: Answers;
  lineOfBusiness: LineOfBusinessName;
};
