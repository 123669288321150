import { z } from 'zod';
import { PaymentFrequency } from '../products/insuranceProductPricing';

export type QuotePerProduct = Record<string, number | null>;

export const quoteInfoSchema = z.object({
  quotePerProduct: z.record(z.number().nullable(), z.string()),
  paymentFrequency: z.nativeEnum(PaymentFrequency),
  annualPremium: z.number().optional().nullable(),
});

export type QuoteInfo = z.infer<typeof quoteInfoSchema>;

export type TotalPremiums = {
  monthly: number;
  annual: number;
};
